/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('tours', {
      redirectTo: '.landing-page',
      parent: 'root',
      url: '/tours',
      template: '<ui-view/>',
      resolve: {
        pageTitle: /* @ngInject */ function(module, $injector) {
          return $injector.invoke(nsUtilProvider.getPageTitle('apps/tours'));
        },
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.tours" */ 'apps/tours').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('tours.landing-page', {
      url: '/:tour_name',
      templateUrl: 'apps/tours/templates/acp-tours-section.ng.html'
    });
}

export default states;
